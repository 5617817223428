const Ask = () => {
  return (
    <div>
      <div className="empty-space"></div>
      <div>문의하기 페이지</div>
    </div>
  );
};

export default Ask;
