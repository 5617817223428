import "./about.css";

const About = () => {
  return (
    <div>
      <div className="about-main">
        <h1>아직 이 홈페이지는 문의 기능만 존재한답니다...</h1>
        <h2>저희에 대한 정보는 이곳으로 와주세요!</h2>
      </div>
    </div>
  );
};

export default About;
